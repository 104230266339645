import { default as agbh99VXTzrOeMeta } from "/vercel/path0/layers/base/pages/agb.vue?macro=true";
import { default as daten_45korrigierenrO0Ksm2GjlMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue?macro=true";
import { default as fin3x1wlAOIRDMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue?macro=true";
import { default as generellcpwFBDX6zEMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45hintenq47BG2ldTKMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45vornedZoc2z3iJuMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue?macro=true";
import { default as sicherheitscode_45zb11iCijwbB8VMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as indexsBk8334br6Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue?macro=true";
import { default as orderK456w9mH1VMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue?macro=true";
import { default as fertigYCJr69cVHpMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue?macro=true";
import { default as indexco3tI9qwy6Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue?macro=true";
import { default as sc_45hintenHFfWWiaAm9Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue?macro=true";
import { default as sc_45vorn5euoZ1U7wvMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue?macro=true";
import { default as sc_45zb19z9DdbEWn4Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue?macro=true";
import { default as zb1ztV6QSFErLMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue?macro=true";
import { default as vorbereitungNBPdgYneSVMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue?macro=true";
import { default as datenschutzjl1RybKV6eMeta } from "/vercel/path0/layers/base/pages/datenschutz.vue?macro=true";
import { default as impressumlDRZsWB8OXMeta } from "/vercel/path0/layers/base/pages/impressum.vue?macro=true";
import { default as index3ljJbrDa9LMeta } from "/vercel/path0/zulassung-digital/pages/index.vue?macro=true";
import { default as _91district_93fMJDdD32NjMeta } from "/vercel/path0/zulassung-digital/pages/landkreis/[district].vue?macro=true";
import { default as landkreiseJFo4ER348YMeta } from "/vercel/path0/zulassung-digital/pages/landkreise.vue?macro=true";
import { default as _91city_93YANQFx5CoWMeta } from "/vercel/path0/zulassung-digital/pages/stadt/[city].vue?macro=true";
import { default as staedtejhIY6Nw5DqMeta } from "/vercel/path0/zulassung-digital/pages/staedte.vue?macro=true";
import { default as altes_45kennzeichenWY5kZ8c1HpMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerde06rsYoo2MwMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/behoerde.vue?macro=true";
import { default as finx2lHxGLiGMMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/fin.vue?macro=true";
import { default as neues_45kennzeichen937K7n5ZkmMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/neues-kennzeichen.vue?macro=true";
import { default as nummer_45zb2vQvFHeHjioMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/nummer-zb2.vue?macro=true";
import { default as reservierungcBR0bGiuV3Meta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45hinten37Yb6eP6vRMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-hinten.vue?macro=true";
import { default as sicherheitscode_45vornec9CrNX0IkQMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-vorne.vue?macro=true";
import { default as sicherheitscode_45zb1MOXi1pCkbBMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as sicherheitscode_45zb2wguQx2CY5aMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-zb2.vue?macro=true";
import { default as versicherung_45steuerAC6f3DhumnMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/versicherung-steuer.vue?macro=true";
import { default as wunschkennzeichen_45behoerdeP6wHW21QMGMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/wunschkennzeichen-behoerde.vue?macro=true";
import { default as wunschkennzeichen_45suchecKQ1XOQOO7Meta } from "/vercel/path0/zulassung-digital/pages/umschreibung/daten/wunschkennzeichen-suche.vue?macro=true";
import { default as indexfjbaJSkKaAMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/index.vue?macro=true";
import { default as order18UESNEECrMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/order.vue?macro=true";
import { default as vorbereitungpFv5ahNCNGMeta } from "/vercel/path0/zulassung-digital/pages/umschreibung/vorbereitung.vue?macro=true";
import { default as _91citySlug_93FVNPfx1MhZMeta } from "/vercel/path0/zulassung-digital/pages/umweltplakette/[citySlug].vue?macro=true";
import { default as checkoutjrqkRDOOwfMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/checkout.vue?macro=true";
import { default as erfolge9e7pLZoUBMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/erfolg.vue?macro=true";
import { default as indexHEoqnYcASeMeta } from "/vercel/path0/zulassung-digital/pages/umweltplakette/index.vue?macro=true";
import { default as uploadno4QK8oc2JMeta } from "/vercel/path0/layers/wkz-checkout/pages/umweltplakette/upload.vue?macro=true";
import { default as widerrufsrechtQmYuV9tGV4Meta } from "/vercel/path0/layers/base/pages/widerrufsrecht.vue?macro=true";
import { default as altes_45kennzeichenW2u0BNL8FyMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/altes-kennzeichen.vue?macro=true";
import { default as behoerdefDoVSQEySwMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/behoerde.vue?macro=true";
import { default as fineY2gvSrfChMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/fin.vue?macro=true";
import { default as neues_45kennzeichen526zhU8bVWMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/neues-kennzeichen.vue?macro=true";
import { default as nummer_45zb2UQ6zYQ8BxBMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/nummer-zb2.vue?macro=true";
import { default as reservierungHmfFN2d7XJMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45zb1yzrkH5udy6Meta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as sicherheitscode_45zb2orPXbWKbSaMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/sicherheitscode-zb2.vue?macro=true";
import { default as versicherung_45steuerJKkxzDqYdqMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/versicherung-steuer.vue?macro=true";
import { default as wunschkennzeichen_45behoerde8PSoG7FmW4Meta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/wunschkennzeichen-behoerde.vue?macro=true";
import { default as wunschkennzeichen_45portalE8DHW89d0oMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/wunschkennzeichen-portal.vue?macro=true";
import { default as wunschkennzeichen_45suche5zsdlZFe8IMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/wunschkennzeichen-suche.vue?macro=true";
import { default as empfohlene_45produktecpFC7dVTtiMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/empfohlene-produkte.vue?macro=true";
import { default as index5B7NEF8JBSMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/index.vue?macro=true";
import { default as orderfr8d1xcYWsMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/order.vue?macro=true";
import { default as vorbereitungUKHI4x72CJMeta } from "/vercel/path0/zulassung-digital/pages/wiederzulassung/vorbereitung.vue?macro=true";
import { default as _91citySlug_931f6VME1SbBMeta } from "/vercel/path0/zulassung-digital/pages/wunschkennzeichen/[citySlug].vue?macro=true";
import { default as _91districtSlug_93hWoz3oabB6Meta } from "/vercel/path0/zulassung-digital/pages/wunschkennzeichen/bezirk/[districtSlug].vue?macro=true";
import { default as checkoutHMBRBWVVmPMeta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/checkout.vue?macro=true";
import { default as erfolgb0SKqCW1a9Meta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/erfolg.vue?macro=true";
import { default as indexJvG3nezve3Meta } from "/vercel/path0/zulassung-digital/pages/wunschkennzeichen/index.vue?macro=true";
import { default as reservierenjIHAT1n7pPMeta } from "/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/reservieren.vue?macro=true";
import { default as zulassungsstatus6n9pdH7Xm5Meta } from "/vercel/path0/zulassung-digital/pages/zulassungsstatus.vue?macro=true";
import { default as _91citySlug_93zJYKKmlNlMMeta } from "/vercel/path0/zulassung-digital/pages/zulassungsstelle/[citySlug].vue?macro=true";
import { default as zustandirK4f00xb3Meta } from "/vercel/path0/zulassung-digital/pages/zustand.vue?macro=true";
export default [
  {
    name: "agb",
    path: "/agb",
    meta: agbh99VXTzrOeMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/agb.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-daten-korrigieren",
    path: "/ausserbetriebsetzung/daten-korrigieren",
    meta: daten_45korrigierenrO0Ksm2GjlMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-daten-fin",
    path: "/ausserbetriebsetzung/daten/fin",
    meta: fin3x1wlAOIRDMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-daten-generell",
    path: "/ausserbetriebsetzung/daten/generell",
    meta: generellcpwFBDX6zEMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-hinten",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten",
    meta: sicherheitscode_45kennzeichen_45hintenq47BG2ldTKMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-vorne",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne",
    meta: sicherheitscode_45kennzeichen_45vornedZoc2z3iJuMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-zb1",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-zb1",
    meta: sicherheitscode_45zb11iCijwbB8VMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung",
    path: "/ausserbetriebsetzung",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-order",
    path: "/ausserbetriebsetzung/order",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-upload-fertig",
    path: "/ausserbetriebsetzung/upload/fertig",
    meta: fertigYCJr69cVHpMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-upload",
    path: "/ausserbetriebsetzung/upload",
    meta: indexco3tI9qwy6Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-upload-sc-hinten",
    path: "/ausserbetriebsetzung/upload/sc-hinten",
    meta: sc_45hintenHFfWWiaAm9Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-upload-sc-vorn",
    path: "/ausserbetriebsetzung/upload/sc-vorn",
    meta: sc_45vorn5euoZ1U7wvMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-upload-sc-zb1",
    path: "/ausserbetriebsetzung/upload/sc-zb1",
    meta: sc_45zb19z9DdbEWn4Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-upload-zb1",
    path: "/ausserbetriebsetzung/upload/zb1",
    meta: zb1ztV6QSFErLMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue").then(m => m.default || m)
  },
  {
    name: "ausserbetriebsetzung-vorbereitung",
    path: "/ausserbetriebsetzung/vorbereitung",
    meta: vorbereitungNBPdgYneSVMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue").then(m => m.default || m)
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    meta: datenschutzjl1RybKV6eMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: "impressum",
    path: "/impressum",
    meta: impressumlDRZsWB8OXMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index3ljJbrDa9LMeta || {},
    component: () => import("/vercel/path0/zulassung-digital/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "landkreis-district",
    path: "/landkreis/:district()",
    meta: _91district_93fMJDdD32NjMeta || {},
    component: () => import("/vercel/path0/zulassung-digital/pages/landkreis/[district].vue").then(m => m.default || m)
  },
  {
    name: "landkreise",
    path: "/landkreise",
    meta: landkreiseJFo4ER348YMeta || {},
    component: () => import("/vercel/path0/zulassung-digital/pages/landkreise.vue").then(m => m.default || m)
  },
  {
    name: "stadt-city",
    path: "/stadt/:city()",
    meta: _91city_93YANQFx5CoWMeta || {},
    component: () => import("/vercel/path0/zulassung-digital/pages/stadt/[city].vue").then(m => m.default || m)
  },
  {
    name: "staedte",
    path: "/staedte",
    meta: staedtejhIY6Nw5DqMeta || {},
    component: () => import("/vercel/path0/zulassung-digital/pages/staedte.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-altes-kennzeichen",
    path: "/umschreibung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/altes-kennzeichen.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-behoerde",
    path: "/umschreibung/daten/behoerde",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/behoerde.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-fin",
    path: "/umschreibung/daten/fin",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/fin.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-neues-kennzeichen",
    path: "/umschreibung/daten/neues-kennzeichen",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/neues-kennzeichen.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-nummer-zb2",
    path: "/umschreibung/daten/nummer-zb2",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/nummer-zb2.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-reservierung",
    path: "/umschreibung/daten/reservierung",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/reservierung.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-sicherheitscode-hinten",
    path: "/umschreibung/daten/sicherheitscode-hinten",
    meta: sicherheitscode_45hinten37Yb6eP6vRMeta || {},
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-hinten.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-sicherheitscode-vorne",
    path: "/umschreibung/daten/sicherheitscode-vorne",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-vorne.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-sicherheitscode-zb1",
    path: "/umschreibung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-zb1.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-sicherheitscode-zb2",
    path: "/umschreibung/daten/sicherheitscode-zb2",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/sicherheitscode-zb2.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-versicherung-steuer",
    path: "/umschreibung/daten/versicherung-steuer",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/versicherung-steuer.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-wunschkennzeichen-behoerde",
    path: "/umschreibung/daten/wunschkennzeichen-behoerde",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/wunschkennzeichen-behoerde.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-daten-wunschkennzeichen-suche",
    path: "/umschreibung/daten/wunschkennzeichen-suche",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/daten/wunschkennzeichen-suche.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung",
    path: "/umschreibung",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/index.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-order",
    path: "/umschreibung/order",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/order.vue").then(m => m.default || m)
  },
  {
    name: "umschreibung-vorbereitung",
    path: "/umschreibung/vorbereitung",
    component: () => import("/vercel/path0/zulassung-digital/pages/umschreibung/vorbereitung.vue").then(m => m.default || m)
  },
  {
    name: "umweltplakette-citySlug",
    path: "/umweltplakette/:citySlug()",
    component: () => import("/vercel/path0/zulassung-digital/pages/umweltplakette/[citySlug].vue").then(m => m.default || m)
  },
  {
    name: "umweltplakette-checkout",
    path: "/umweltplakette/checkout",
    meta: checkoutjrqkRDOOwfMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/checkout.vue").then(m => m.default || m)
  },
  {
    name: "umweltplakette-erfolg",
    path: "/umweltplakette/erfolg",
    meta: erfolge9e7pLZoUBMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/erfolg.vue").then(m => m.default || m)
  },
  {
    name: "umweltplakette",
    path: "/umweltplakette",
    component: () => import("/vercel/path0/zulassung-digital/pages/umweltplakette/index.vue").then(m => m.default || m)
  },
  {
    name: "umweltplakette-upload",
    path: "/umweltplakette/upload",
    meta: uploadno4QK8oc2JMeta || {},
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/umweltplakette/upload.vue").then(m => m.default || m)
  },
  {
    name: "widerrufsrecht",
    path: "/widerrufsrecht",
    meta: widerrufsrechtQmYuV9tGV4Meta || {},
    component: () => import("/vercel/path0/layers/base/pages/widerrufsrecht.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-altes-kennzeichen",
    path: "/wiederzulassung/daten/altes-kennzeichen",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/altes-kennzeichen.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-behoerde",
    path: "/wiederzulassung/daten/behoerde",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/behoerde.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-fin",
    path: "/wiederzulassung/daten/fin",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/fin.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-neues-kennzeichen",
    path: "/wiederzulassung/daten/neues-kennzeichen",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/neues-kennzeichen.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-nummer-zb2",
    path: "/wiederzulassung/daten/nummer-zb2",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/nummer-zb2.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-reservierung",
    path: "/wiederzulassung/daten/reservierung",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/reservierung.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-sicherheitscode-zb1",
    path: "/wiederzulassung/daten/sicherheitscode-zb1",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/sicherheitscode-zb1.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-sicherheitscode-zb2",
    path: "/wiederzulassung/daten/sicherheitscode-zb2",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/sicherheitscode-zb2.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-versicherung-steuer",
    path: "/wiederzulassung/daten/versicherung-steuer",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/versicherung-steuer.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-behoerde",
    path: "/wiederzulassung/daten/wunschkennzeichen-behoerde",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/wunschkennzeichen-behoerde.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-portal",
    path: "/wiederzulassung/daten/wunschkennzeichen-portal",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/wunschkennzeichen-portal.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-daten-wunschkennzeichen-suche",
    path: "/wiederzulassung/daten/wunschkennzeichen-suche",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/daten/wunschkennzeichen-suche.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-empfohlene-produkte",
    path: "/wiederzulassung/empfohlene-produkte",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/empfohlene-produkte.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung",
    path: "/wiederzulassung",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/index.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-order",
    path: "/wiederzulassung/order",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/order.vue").then(m => m.default || m)
  },
  {
    name: "wiederzulassung-vorbereitung",
    path: "/wiederzulassung/vorbereitung",
    component: () => import("/vercel/path0/zulassung-digital/pages/wiederzulassung/vorbereitung.vue").then(m => m.default || m)
  },
  {
    name: "wunschkennzeichen-citySlug",
    path: "/wunschkennzeichen/:citySlug()",
    component: () => import("/vercel/path0/zulassung-digital/pages/wunschkennzeichen/[citySlug].vue").then(m => m.default || m)
  },
  {
    name: "wunschkennzeichen-bezirk-districtSlug",
    path: "/wunschkennzeichen/bezirk/:districtSlug()",
    component: () => import("/vercel/path0/zulassung-digital/pages/wunschkennzeichen/bezirk/[districtSlug].vue").then(m => m.default || m)
  },
  {
    name: "wunschkennzeichen-checkout",
    path: "/wunschkennzeichen/checkout",
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/checkout.vue").then(m => m.default || m)
  },
  {
    name: "wunschkennzeichen-erfolg",
    path: "/wunschkennzeichen/erfolg",
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/erfolg.vue").then(m => m.default || m)
  },
  {
    name: "wunschkennzeichen",
    path: "/wunschkennzeichen",
    component: () => import("/vercel/path0/zulassung-digital/pages/wunschkennzeichen/index.vue").then(m => m.default || m)
  },
  {
    name: "wunschkennzeichen-reservieren",
    path: "/wunschkennzeichen/reservieren",
    component: () => import("/vercel/path0/layers/wkz-checkout/pages/wunschkennzeichen/reservieren.vue").then(m => m.default || m)
  },
  {
    name: "zulassungsstatus",
    path: "/zulassungsstatus",
    component: () => import("/vercel/path0/zulassung-digital/pages/zulassungsstatus.vue").then(m => m.default || m)
  },
  {
    name: "zulassungsstelle-citySlug",
    path: "/zulassungsstelle/:citySlug()",
    meta: _91citySlug_93zJYKKmlNlMMeta || {},
    component: () => import("/vercel/path0/zulassung-digital/pages/zulassungsstelle/[citySlug].vue").then(m => m.default || m)
  },
  {
    name: "zustand",
    path: "/zustand",
    component: () => import("/vercel/path0/zulassung-digital/pages/zustand.vue").then(m => m.default || m)
  }
]