import { useScriptGoogleAnalytics, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleAnalytics = useScriptGoogleAnalytics({"id":"G-6N48ZHWGGP"})
    return { provide: { $scripts: { googleAnalytics } } }
  }
})