import { useRequest } from 'vue-request'
import type { OrderSource } from '@gks/prisma'

type UmschreibungData = Parameters<ReturnType<typeof useNuxtApp>['$trpc']['personal']['umschreibung']['createOrder']['mutate']>['0']['umschreibung']

export default defineStore('umschreibung', () => {
  const { email, phone } = useSharedOrderInfo()

  const data = ref<UmschreibungData>({
    fin: '',
    scZb1: '',
    scVorn: '',
    scHinten: '',
    scZb2: '',
    nummerZb2: '',
    aktuellesKennzeichen: {
      kombination: {
        token: '',
        letters: '',
        numbers: ''
      },
      elektro: false
    },
    bankAccount: {
      iban: ''
    },
    evbNummer: '',
    naechstesKennzeichen: {
      type: 'reservierung',
      elektro: false,
      pin: '',
      kombination: {
        token: '',
        letters: '',
        numbers: ''
      },
    }
  })

  const officeId = ref('')

  const dto = computed(() => {
    const { fin, scZb1, scZb2, scHinten, scVorn, naechstesKennzeichen, nummerZb2, evbNummer, aktuellesKennzeichen, bankAccount } = data.value

    const pin = naechstesKennzeichen.type === 'reservierung' ? (naechstesKennzeichen.pin || undefined) : undefined
    const naechstesKennzeichenKombination = naechstesKennzeichen.type === 'reservierung' ? naechstesKennzeichen.kombination : undefined

    const selectedPlate = useWkzSearchesStore().selectedPlate
    const vehicleType = naechstesKennzeichen.type === 'reservierung' ? selectedPlate?.search?.vehicle : undefined

    return {
      email: email.value,
      source: useRuntimeConfig().public.source as OrderSource,
      phone: phone.value,
      umschreibung: {
        fin,
        scZb1,
        scVorn: naechstesKennzeichen?.type === 'vorhanden' ? undefined : (scVorn || null),
        scHinten: naechstesKennzeichen?.type === 'vorhanden' ? undefined : scHinten,
        scZb2: scZb2 || undefined,
        nummerZb2: nummerZb2 || undefined,
        aktuellesKennzeichen,
        bankAccount,
        evbNummer,
        naechstesKennzeichen: {
          type: naechstesKennzeichen.type,
          elektro: naechstesKennzeichen.elektro,
          pin,
          kombination: naechstesKennzeichenKombination
        }
      },
      vehicleType,
      officeId: officeId.value
    }
  })

  const { runAsync: createOrder, loading: createOrderLoading } = useRequest(async () => {
    // Sometimes people copy the url and try to create an order without the required data in local storage
    // In this case we should navigate the user to the start page and not let them create an order
    if (!dto.value.email) {
      return navigateTo('/', { replace: true })
    }

    const { checkoutSessionUrl } = await useNuxtApp().$trpc.personal.umschreibung.createOrder.mutate(dto.value)
  
    window.location.href = checkoutSessionUrl
    // fake wait until redirect happens
    await new Promise(res => setTimeout(res, 10000))
  }, { manual: true })

  return {
    data,
    dto,
    createOrder,
    createOrderLoading,
    officeId
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    paths: ['data', 'officeId']
  }
})
